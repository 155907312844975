export const AnimationLinks = () => {
    const malerNettsideVideoUrl = "https://growebmp4container-euczdjadazhtdkhf.z02.azurefd.net/growebmp4container/maler-nettside.webm";
    const aiByggerVideoUrl = "https://growebmp4container-euczdjadazhtdkhf.z02.azurefd.net/growebmp4container/ai-builder-animation.webm";
    const guiderVideoUrl = "https://growebmp4container-euczdjadazhtdkhf.z02.azurefd.net/growebmp4container/guides-animation.webm";
    const logoUrl = "https://growebmp4container-euczdjadazhtdkhf.z02.azurefd.net/growebmp4container/logo-final.webp"

    return {
        malerAnimation: malerNettsideVideoUrl,
        aiByggerAnimation: aiByggerVideoUrl,
        guiderAnimation: guiderVideoUrl,
        logoUrl
    };
}
