import React from 'react';
import {Box, Container, Typography} from '@mui/material';
import InfoIcon from "@mui/icons-material/InfoOutlined";
import {color} from "../theme";
import modalStyle from '../components/customComponents/modalStyle'
import PlanOptions from "../components/stripe/PlanOptions";

const RestartSubscriptionPage = () => {
    return (
        <Container sx={{
            ...modalStyle, maxWidth: {xs: '75vw', sm: '80vw', md: '75vw', lg: '75vw', xl: '70vw'},
            width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '32px 0px',
            left: {xs: '55%', sm: '57%', md: '60%', lg: '57%', xl: '55%'},
        }}>
            <InfoIcon sx={{fontSize: '2.5rem', color: color.lightBlue.baseBlue100}}/>
            <Typography variant="h5" sx={{mb: 1, textAlign: 'center'}}>
                Ditt Groweb abonnement har utløpt.
            </Typography>
            <Typography variant="body" sx={{mb: 2, textAlign: 'center'}}>
                Forny din Groweb-abonnement for å få tilgang til Nettside funksjonen og bruke nettsidebyggeren.
            </Typography>
            <PlanOptions trial={false}/>
        </Container>
    );
};

export default RestartSubscriptionPage;
