import React, { useEffect } from 'react';
import { getSubscriptionStatus } from '../../helperFunctions/stripe/getSubscriptionStatus';
import SuccessPage from "../../components/paymentSuccess/SuccessPage";

const TrialSuccessPage = () => {
    useEffect(() => {
        getSubscriptionStatus();
    }, []);

    return (
        <SuccessPage planType="fullpakke" isTrial={true} />
    );
};

export default TrialSuccessPage;
