import React, {useCallback, useEffect, useState} from 'react';
import {fetchTemplates} from '../helperFunctions/nettside/fetchTemplates';
import {fetchTemplateCategories} from '../helperFunctions/nettside/fetchTemplateCategories';
import {Alert, Box, Container, Modal, Typography, useMediaQuery} from '@mui/material';
import Navbar from "../components/navbar/Navbar";
import WebsiteForm from "../components/nettside/WebsiteForm";
import handleFormSubmit from "../helperFunctions/nettside/handleFormSubmit";
import TemplateSubpage from "../components/nettside/TemplateSubpage";
import MyWebsitesSubpage from "../components/nettside/MyWebsitesSubpage";
import {CustomButton} from '../components/customComponents/CustomButton';
import LinkModal from "../components/nettside/LinkModal";
import AiBuilderModal from "../components/nettside/AiBuilderModal";
import {fetchSubscriptionStatus} from "../helperFunctions/stripe/fetchSubscriptionStatus";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {useTheme} from "@mui/material/styles";
import templateTranslations from "../helperFunctions/nettside/translations/templateTranslations";
import SkeletonTemplateSubpage from "../components/skeleton/SkeletonTemplateSubpage";
import SkeletonMyWebsitesSubpage from "../components/skeleton/SkeletonMyWebsitesSubpage";
import RestartSubscriptionPage from "./RestartSubscriptionPage";
import modalStyle from "../components/customComponents/modalStyle";
import PaywallModal from "../components/stripe/PaywallModal";
import handleAiFormSubmit from "../helperFunctions/nettside/handleAiFormSubmit";
import AiLoadingModal from "../components/nettside/AiLoadingModal";
import LoadingModal from "../components/nettside/LoadingModal";
import {useLocation} from "react-router-dom";

const DISPLAY_INCREMENT = 12;

const NettsidePage = () => {
    const location = useLocation();

    const defaultActivePage = location.pathname.includes('nettsidemaler')
        ? 'Nettsidemaler'
        : 'Mine nettsider';

    const [templates, setTemplates] = useState([]);
    const [templateCategories, setTemplateCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(3304263); // Default category ID for "Enkel" templates
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [activePage, setActivePage] = useState(defaultActivePage);
    const [displayCount, setDisplayCount] = useState(DISPLAY_INCREMENT);
    const [ssoLink, setSsoLink] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredTemplates, setFilteredTemplates] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [openBuildModal, setOpenBuildModal] = useState(false);
    const [openPaywallModal, setOpenPaywallModal] = useState(false);
    const [instantModal, setInstantModal] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);

    // Modals
    const [openAiBuilderModal, setOpenAiBuilderModal] = useState(false);
    const [openLinkModal, setOpenLinkModal] = useState(false);
    const [openAiLoadingModal, setOpenAiLoadingModal] = useState(false);
    const [loadingDuration, setLoadingDuration] = useState(5000); // For AI loading modal, default to 5 seconds

    // Subscription
    const [subscriptionStatus, setSubscriptionStatus] = useState(localStorage.getItem('subscription_status') || 'free');
    const [trialDays, setTrialDays] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [websiteQuota, setWebsiteQuota] = useState(1);
    const [loading, setLoading] = useState(true); // Loading state for subscription status
    const breakpointTheme = useTheme();
    const isMediumScreen = useMediaQuery(breakpointTheme.breakpoints.down('md'));
    const isSmallScreen = useMediaQuery(breakpointTheme.breakpoints.down('sm'));
    const marginLeftResponsive = isSmallScreen ? '15%' : isMediumScreen ? '15%' : '15%';

    const handleButtonClick = useCallback((page) => {
        setActivePage(page);
    }, []);

    const handleAiLoadingComplete = () => {
        setOpenAiLoadingModal(false);
        setOpenLinkModal(true);
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        const updateActivePage = () => {
            if (location.pathname.includes('nettsidemaler')) {
                setActivePage('Nettsidemaler');
            } else {
                setActivePage('Mine nettsider');
            }
        };

        const checkAiModal = () => {
            if (params.get('openAiModal') === 'true') {
                setOpenAiBuilderModal(true);
            }
        };

        updateActivePage();
        checkAiModal();
    }, [location]);

    useEffect(() => {
        const getSubscriptionStatus = async () => {
            const subscriptionData = await fetchSubscriptionStatus();
            if (subscriptionData) {
                const status = subscriptionData.status === 'free' ? 'gratis' : subscriptionData.status;
                setSubscriptionStatus(status);
                localStorage.setItem('subscription_status', status);
                localStorage.setItem('subscription_type', subscriptionData.subscription_type);
                setTrialDays(subscriptionData.trialDays);
                setIsAdmin(subscriptionData.isAdmin);
                setWebsiteQuota(subscriptionData.websiteQuota)
            }
            setLoading(false);
        };

        const loadData = async () => {
            await getSubscriptionStatus();
        };

        loadData()
    }, []);

    useEffect(() => {
        if (activePage === 'Nettsidemaler') {
            const loadTemplateCategories = async () => {
                try {
                    const categories = await fetchTemplateCategories();
                    if (categories && Array.isArray(categories)) {
                        setTemplateCategories(categories);
                    } else {
                        setTemplateCategories([]);
                    }
                } catch (error) {
                    setTemplateCategories([]);
                }
            };

            loadTemplateCategories();
            loadTemplates();
        }
    }, [activePage]);

    useEffect(() => {
        if (activePage === 'Nettsidemaler') {
            const newFilteredTemplates = templates.filter(template => {
                const translatedTitle = templateTranslations[template.title] || template.title;
                return (!searchQuery || translatedTitle.toLowerCase().includes(searchQuery.toLowerCase()));
            });

            setFilteredTemplates(newFilteredTemplates);
            setDisplayCount(DISPLAY_INCREMENT);
        }
    }, [templates, searchQuery, activePage]);

    const loadTemplates = async (categoryId = 3304263) => {
        setIsLoading(true);
        try {
            const data = await fetchTemplates(categoryId);
            if (data && Array.isArray(data)) {
                setTemplates(data);
                setErrorMessage('');
            } else {
                setErrorMessage('En feil oppstod ved henting av maler. Prøv igjen senere.');
            }
        } catch (error) {
            setErrorMessage('En feil oppstod ved henting av maler. Prøv igjen senere.');
        }
        setIsLoading(false);
    };

    const handleCategoryChange = async (categoryId) => {
        setSelectedCategory(categoryId);
        setAnchorEl(null);
        await loadTemplates(categoryId);
    };

    const handleLoadMore = useCallback(() => {
        setDisplayCount(prevCount => Math.min(prevCount + DISPLAY_INCREMENT, filteredTemplates.length));
    }, [filteredTemplates.length]);

    const displayedTemplates = filteredTemplates.slice(0, displayCount);

    const handleSearchChange = useCallback((event) => {
        setSearchQuery(event.target.value);
    }, []);

    const handleOpenBuildModal = () => {
        if (!['trialing', 'active'].includes(subscriptionStatus) && !isAdmin) {
            setInstantModal(false);
            setOpenPaywallModal(true);
        } else {
            setOpenBuildModal(true);
        }
    };

    const handleCloseBuildModal = () => {
        setSsoLink('');
        setOpenBuildModal(false);
        setInstantModal(false);
    };

    const handleCloseLinkModal = useCallback(() => {
        setOpenLinkModal(false);
        setActivePage('Mine nettsider');
        window.location.reload();
    }, []);

    const handleFormChange = (event) => {
        const {name, value} = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleTemplateSelect = (projectId) => {
        setSelectedProjectId(projectId);
        handleOpenBuildModal();
    };

    const handleOpenAiBuilderModal = () => {
        if (!['trialing', 'active'].includes(subscriptionStatus) && !isAdmin) {
            setInstantModal(false);
            setOpenPaywallModal(true);
        } else {
            setOpenAiBuilderModal(true);
        }
    };

    const handleCloseAiBuilderModal = () => {
        setOpenAiBuilderModal(false);
        resetAiBuilderModal();
    };

    const handleClosePaywallModal = () => {
        setOpenPaywallModal(false);
    };

    const handleCloseRestartModal = () => {
        setActivePage('Mine nettsider');
    };

    const [aiFormData, setAiFormData] = useState({
        businessName: '',
        industry: '',
        description: '',
        phone: '',
        email: '',
        language: null,
        city: ''
    });

    const resetAiBuilderModal = () => {
        setAiFormData({
            businessName: '',
            industry: '',
            description: '',
            phone: '',
            email: '',
            language: null,
            city: ''
        });
        setErrorMessage('');
    };

    useEffect(() => {
        if (openLinkModal && ssoLink) {
            console.log("Modal should open with link:", ssoLink);
        }
    }, [openLinkModal, ssoLink]);

    return (
        <Container
            sx={{marginTop: 8, width: '100vw', maxWidth: {xs: '100%', sm: '90%', md: '90%', lg: '90%', xl: '90%'}}}>
            <Navbar/>
            {subscriptionStatus === 'canceled' ? (
                <RestartSubscriptionPage handleClose={handleCloseRestartModal}/>
            ) : (
                <>
                    {activePage !== 'Mine nettsider' && (
                        <div
                            onClick={() => handleButtonClick('Mine nettsider')}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                fontWeight: 'bold',
                                marginLeft: '170px',
                                marginBottom: '1em'
                            }}
                        >
                            <ArrowBackIosIcon/> <Typography variant="h5">Tilbake</Typography>
                        </div>
                    )}
                    <Typography variant="h3"
                                sx={{
                                    mb: activePage === 'Nettsidemaler' ? '0' : '1em',
                                    fontFamily: 'Fraunces',
                                    mt: activePage === 'Nettsidemaler' ? '0' : 1.5,
                                    ml: {xs: '15%', sm: '15%', md: '14%', lg: '15%', xl: '12%'},
                                    textAlign: 'left',
                                    visibility: activePage === 'Nettsidemaler' ? 'hidden' : 'visible',
                                    width: activePage === 'Nettsidemaler' ? '0' : 'auto',
                                    height: activePage === 'Nettsidemaler' ? '0' : 'auto',
                                    display: activePage === 'Nettsidemaler' ? 'none' : 'block',
                                }}>
                        Nettside
                    </Typography>
                    {loading ? (
                        activePage === 'Nettsidemaler' ? (
                            <SkeletonTemplateSubpage/>
                        ) : (
                            <SkeletonMyWebsitesSubpage/>
                        )
                    ) : (
                        <>
                            {activePage === 'Nettsidemaler' && (
                                <TemplateSubpage
                                    searchQuery={searchQuery}
                                    handleSearchChange={handleSearchChange}
                                    isLoading={isLoading}
                                    errorMessage={errorMessage}
                                    displayedTemplates={displayedTemplates}
                                    setSelectedTemplateId={handleTemplateSelect}
                                    handleOpenBuildModal={handleOpenBuildModal}
                                    displayCount={displayCount}
                                    handleLoadMore={handleLoadMore}
                                    filteredTemplates={filteredTemplates}
                                    templateCategories={templateCategories}
                                    selectedCategory={selectedCategory}
                                    handleCategoryChange={handleCategoryChange}
                                />
                            )}
                            {activePage === 'Mine nettsider' && (
                                <MyWebsitesSubpage
                                    subscriptionStatus={subscriptionStatus}
                                    trialDays={trialDays}
                                    isAdmin={isAdmin}
                                    setActivePage={setActivePage} // Pass the navigateToTemplates function to MyWebsitesSubpage
                                    handleOpenAiBuilderModal={handleOpenAiBuilderModal} // Pass handleOpenAiBuilderModal function
                                    websiteQuota={websiteQuota}
                                />
                            )}
                        </>
                    )}

                    <Modal
                        open={openBuildModal}
                        onClose={(event, reason) => {
                            if (isSubmitting) {
                                event.preventDefault();
                            } else {
                                handleCloseBuildModal();
                            }
                        }}
                        aria-labelledby="build-website-modal-title"
                        aria-describedby="build-website-modal-description"
                    >
                        {errorMessage ? (
                            <Box sx={modalStyle}>
                                <Typography variant="h5" component="h2" sx={{textAlign: 'center', mt: '2em'}}>
                                    Feilmelding
                                </Typography>
                                <Alert severity="error" sx={{mt: 2}}>
                                    {errorMessage}
                                </Alert>
                                <Box component="div" sx={{textAlign: 'center', mt: 3}}>
                                    <CustomButton onClick={handleCloseBuildModal} variant="contained"
                                                  colors="tertiary">
                                        Lukk
                                    </CustomButton>
                                </Box>
                            </Box>
                        ) : (
                            <WebsiteForm
                                formData={formData}
                                handleChange={handleFormChange}
                                handleSubmit={(e) =>
                                    handleFormSubmit(
                                        e,
                                        selectedProjectId,
                                        formData,
                                        setSsoLink,
                                        setIsSubmitting,
                                        setErrorMessage,
                                        instantModal,
                                        true, // sendEmail
                                        setOpenLinkModal,
                                        setOpenBuildModal
                                    )
                                }
                                handleClose={handleCloseBuildModal}
                                modalStyle={modalStyle}
                                setSsoLink={setSsoLink}
                            />
                        )}
                    </Modal>

                    <Modal // Link Modal
                        open={openLinkModal}
                        onClose={() => handleCloseLinkModal()}
                        aria-labelledby="link-modal-title"
                        aria-describedby="link-modal-description"
                    >
                        <LinkModal
                            ssoLink={ssoLink}
                            handleClose={() => handleCloseLinkModal()}
                            modalStyle={modalStyle}
                        />
                    </Modal>

                    <AiBuilderModal
                        open={openAiBuilderModal}
                        handleClose={handleCloseAiBuilderModal}
                        setSsoLink={setSsoLink}
                        aiFormData={aiFormData}
                        setAiFormData={setAiFormData}
                        setErrorMessage={setErrorMessage}
                        handleSubmit={(e) =>
                            handleAiFormSubmit(
                                e,
                                aiFormData,
                                setSsoLink,
                                setIsSubmitting,
                                setErrorMessage,
                                setOpenLinkModal,
                                handleCloseAiBuilderModal,
                                setOpenAiLoadingModal,
                                setLoadingDuration
                            )
                        }
                    />

                    {/* Loading Modal */}
                    <AiLoadingModal
                        open={openAiLoadingModal}
                        duration={loadingDuration}
                        handleClose={handleAiLoadingComplete}
                    />

                    <LoadingModal open={isSubmitting}/>

                    <PaywallModal // Paywall modal
                        openPaywallModal={openPaywallModal}
                        handleClosePaywallModal={handleClosePaywallModal}
                        trial={trialDays !== 0}
                        text={"Du må ha et aktivt abonnement for å opprette en nettside"}
                        aria-labelledby="paywall-modal-title"
                        aria-describedby="paywall-modal-description"
                    />
                </>
            )}
        </Container>
    );
};

export default NettsidePage;
