import React, {useEffect} from 'react';
import {getSubscriptionStatus} from '../../helperFunctions/stripe/getSubscriptionStatus';
import SuccessPage from "../../components/paymentSuccess/SuccessPage";

const TrialNettsidePage = () => {

    useEffect(() => {
        getSubscriptionStatus();
    }, []);

    return (
        <SuccessPage planType="nettsideplan" isTrial={true} />
    );
};

export default TrialNettsidePage;
