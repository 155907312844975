// PlanOptions.js
import React, {useState} from 'react';
import {Box, Grid, List, ListItem, ListItemText, Paper, Typography} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StripeButton from './StripeButton';
import '../../assets/css/PlanOptions.css';
import {color} from "../../theme";

const PlanOptions = (trial) => {
    const [selectedPlan, setSelectedPlan] = useState('');

    return (
        <Box
            sx={{
                maxWidth: '90vw',
                maxHeight: '90vh',
                width: 'fit-content',
                overflowY: 'auto',
                m: 'auto',
                mt: 0,
                p: 2,
            }}
        >
            <Typography variant="h4" textAlign="center" gutterBottom sx={{mb: 4}}>
                Velg en plan som passer deg {trial ? ('(30 dager gratis prøveperiode)') : ('')}</Typography>
            <Grid container spacing={2} justifyContent="space-around">
                {/* Website-Only Plan */}
                <Grid item xs={12} sm={6} md={5}>
                    <Paper
                        elevation={4}
                        onClick={() => setSelectedPlan('website_only')}
                        sx={{
                            p: 4,
                            textAlign: 'center',
                            backgroundColor: '#fcfcf9',
                            border: '2px solid',
                            borderColor: selectedPlan === 'website_only' ? color.lightBlue.baseBlue100 : '#e0e0e0',
                            transition: 'all 0.3s ease',
                            borderRadius: 2,
                            boxShadow: selectedPlan === 'website_only' ? '0px 8px 24px rgba(0, 0, 0, 0.1)' : '',
                            '&:hover': {
                                boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.1)',
                                cursor: 'pointer',
                                borderColor: color.lightBlue.baseBlue100,
                            },
                        }}
                    >
                        <Typography
                            variant="h4"
                            component="h2"
                            gutterBottom
                            sx={{
                                fontSize: {xs: '1rem', sm: '1.5rem', md: '2rem'},
                                fontFamily: 'Fraunces',
                                fontWeight: 'bold',
                            }}
                        >
                            Nettsideplan
                        </Typography>
                        <Typography
                            variant="h5"
                            component="p"
                            gutterBottom
                            sx={{
                                fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.2rem'},
                                fontWeight: 'bold',
                                color: color.lightBlue.baseBlue100,
                            }}
                        >
                            299 NOK/måned
                        </Typography>
                        <Typography
                            variant="body1"
                            component="p"
                            gutterBottom
                            sx={{
                                fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.2rem'},
                            }}
                        >
                            Bygg en profesjonell nettside enkelt og raskt.
                        </Typography>
                        <List>
                            <ListItem>
                                <CheckCircleIcon sx={{color: color.lightBlue.baseBlue100, mr: 1}}/>
                                <ListItemText
                                    primary="Bygg flotte nettsider med 200+ maler eller bruk vår AI-bygger for raskt resultat."
                                    primaryTypographyProps={{
                                        sx: {fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.2rem'}},
                                    }}
                                />
                            </ListItem>
                            <ListItem>
                                <CheckCircleIcon sx={{color: color.lightBlue.baseBlue100, mr: 1}}/>
                                <ListItemText
                                    primary="Publiser på eget domene eller vårt, og bli synlig med ett klikk."
                                    primaryTypographyProps={{
                                        sx: {fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.2rem'}},
                                    }}
                                />
                            </ListItem>
                            <ListItem>
                                <CheckCircleIcon sx={{color: color.lightBlue.baseBlue100, mr: 1}}/>
                                <ListItemText
                                    primary="Inkluderer 1 nettside – perfekt for små bedrifter og prosjekter."
                                    primaryTypographyProps={{
                                        sx: {fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.2rem'}},
                                    }}
                                />
                            </ListItem>
                        </List>
                        <StripeButton
                            text="Velg Nettsideplan"
                            productType="website_only"
                            trial={trial}
                            sx={{
                                mt: 3,
                                p: 2,
                                fontWeight: 'bold',
                                backgroundColor: color.lightBlue.baseBlue100,
                                '&:hover': {
                                    backgroundColor: color.darkBlue.baseBlue100,
                                },
                            }}
                        />
                    </Paper>
                </Grid>
                {/* Full Package Plan */}
                <Grid item xs={12} sm={6} md={5}>
                    <Paper
                        elevation={4}
                        onClick={() => setSelectedPlan('full')}
                        sx={{
                            p: 4,
                            textAlign: 'center',
                            backgroundColor: '#fcfcf9',
                            border: '2px solid',
                            borderColor: selectedPlan === 'full' ? color.lightBlue.baseBlue100 : '#e0e0e0',
                            transition: 'all 0.3s ease',
                            borderRadius: 2,
                            boxShadow: selectedPlan === 'full' ? '0px 8px 24px rgba(0, 0, 0, 0.1)' : '',
                            '&:hover': {
                                boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.1)',
                                cursor: 'pointer',
                                borderColor: color.lightBlue.baseBlue100,
                            },
                        }}
                    >
                        <Typography
                            variant="h4"
                            component="h2"
                            gutterBottom
                            sx={{
                                fontSize: {xs: '1rem', sm: '1.5rem', md: '2rem'},
                                fontFamily: 'Fraunces',
                                fontWeight: 'bold',
                            }}
                        >
                            Nettside + Guider
                        </Typography>
                        <Typography
                            variant="h5"
                            component="p"
                            gutterBottom
                            sx={{
                                fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.2rem'},
                                fontWeight: 'bold',
                                color: color.lightBlue.baseBlue100,
                            }}
                        >
                            499 NOK/måned
                        </Typography>
                        <Typography
                            variant="body1"
                            component="p"
                            gutterBottom
                            sx={{
                                fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.2rem'},
                            }}
                        >
                            Alt du trenger for nettside og digital markedsføring.
                        </Typography>
                        <List>
                            <ListItem>
                                <CheckCircleIcon sx={{color: color.lightBlue.baseBlue100, mr: 1}}/>
                                <ListItemText
                                    primary="Alt det Nettsideplan har pluss:"
                                    primaryTypographyProps={{
                                        sx: {fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.2rem'}, fontWeight: 'bold'},
                                    }}
                                />
                            </ListItem>
                            <ListItem>
                                <CheckCircleIcon sx={{color: color.lightBlue.baseBlue100, mr: 1}}/>
                                <ListItemText
                                    primary="Få tilgang til alle våre kurs – lær digital markedsføring fra eksperter."
                                    primaryTypographyProps={{
                                        sx: {fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.2rem'}},
                                    }}
                                />
                            </ListItem>
                            <ListItem>
                                <CheckCircleIcon sx={{color: color.lightBlue.baseBlue100, mr: 1}}/>
                                <ListItemText
                                    primary="Lær SEO og tiltrekk mer trafikk til nettsiden din."
                                    primaryTypographyProps={{
                                        sx: {fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.2rem'}},
                                    }}
                                />
                            </ListItem>
                            <ListItem>
                                <CheckCircleIcon sx={{color: color.lightBlue.baseBlue100, mr: 1}}/>
                                <ListItemText
                                    primary="Få det beste ut av nettsidebygging og markedsføring uten begrensninger!"
                                    primaryTypographyProps={{
                                        sx: {fontSize: {xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.2rem'}},
                                    }}
                                />
                            </ListItem>
                        </List>
                        <StripeButton
                            text="Velg Full pakke"
                            productType="full"
                            trial={trial}
                            sx={{
                                mt: 3,
                                p: 2,
                                fontWeight: 'bold',
                                backgroundColor: color.lightBlue.baseBlue100,
                                '&:hover': {
                                    backgroundColor: color.lightBlue.baseBlue100,
                                },
                            }}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default PlanOptions;
