import React from 'react';
import {Box, Card, CardContent, Container, Typography} from '@mui/material';
import {AnimationLinks} from "../../animations";
import {useNavigate} from "react-router-dom";
import {ArrowRightAltRounded} from "@mui/icons-material";

const SuccessPageLayout = ({title, subscriptionTypeText, children}) => {
    const navigate = useNavigate();
    const goToHome = () => {
        navigate('/');
    }

    return (
        <Container disableGutters maxWidth={false}
                   sx={{display: 'flex', flexDirection: 'row', height: '100vh', width: '100vw'}}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <Card sx={{width: '100%', height: '100%', textAlign: 'center'}}>
                    <div className="logoImg" onClick={goToHome} title="Hjem"
                         style={{margin: "0.5em 0em 0em 1em", display: 'flex'}}>
                        <img src={AnimationLinks().logoUrl} alt={"logo-image"} style={{height: "5em", width: "10em"}}/>
                    </div>
                    <CardContent>
                        <Typography variant="h2" component="div" sx={{fontFamily: 'Fraunces'}} gutterBottom>
                            {title}
                        </Typography>
                        <Typography variant="body" sx={{marginTop: '0.5em'}}>
                            {subscriptionTypeText}
                        </Typography>
                        <Typography
                            variant="caption"
                            display="block"
                            sx={{width: '76%', margin: '1.5em auto 1em', fontWeight: 'bold'}}
                        >
                            Nå kan du starte jobben med å få flere kunder på nett!
                            <br/>
                            Det er lettere enn du tror.
                        </Typography>
                    </CardContent>
                    {/* Cards Section */}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            flexWrap: 'wrap',
                            gap: {xs: '1em', sm: '1em', md: '2em'},
                            flexGrow:'1',
                            height: {xs: '10%', sm: '10%', md: '15%'},
                        }}
                    >
                        {children}
                    </Box>
                    <Typography variant="h5" component="div" onClick={goToHome} sx={{
                        fontFamily: 'Fraunces',
                        right: '0',
                        bottom: '0',
                        position: 'absolute',
                        margin: '1.5em',
                        textAlign: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        cursor:'pointer'
                    }} gutterBottom>
                        Til Dashboard <ArrowRightAltRounded></ArrowRightAltRounded>
                    </Typography>
                </Card>
            </Box>
        </Container>
    );
};

export default SuccessPageLayout;
