import React, {useEffect} from 'react';
import {getSubscriptionStatus} from '../../helperFunctions/stripe/getSubscriptionStatus';
import SuccessPage from "../../components/paymentSuccess/SuccessPage";

const PaymentSuccessNettsidePage = () => {
    useEffect(() => {
        getSubscriptionStatus();
    }, []);

    return (
        <SuccessPage planType="nettsideplan" isTrial={false} />
    );
};

export default PaymentSuccessNettsidePage;
