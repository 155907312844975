import React from 'react';
import {useNavigate} from 'react-router-dom';
import SuccessPageLayout from './SuccessPageLayout';
import ActionCard from './ActionCard';
import {Box} from '@mui/material';
import {AnimationLinks} from "../../animations";

const SuccessPage = ({planType, isTrial}) => {
    const navigate = useNavigate();

    const navigateToTemplates = () => {
        navigate('/nettside/nettsidemaler');
    };

    const navigateToAi = () => {
        navigate('/nettside?openAiModal=true')
    }

    const cards = [
        {
            title: 'Velg en mal',
            description: 'Sett i gang med å lage din egen nettside - det er enkelt!',
            videoSrc: AnimationLinks().malerAnimation,
            onClick: navigateToTemplates,
        },
        planType === 'nettsideplan' && {
            title: 'Bygg en nettside med AI',
            description: 'Trenger du noe raskt? La AI bygge et utkast av nettsiden din!',
            videoSrc: AnimationLinks().aiByggerAnimation,
            onClick: navigateToAi,
        },
        planType === 'fullpakke' && {
            title: 'Lær annonsering',
            description: 'Har du allerede en nettside? Få flere kunder med annonsering.',
            onClick: () => navigate('/guider'),
            videoSrc: AnimationLinks().guiderAnimation,
        },
    ].filter(Boolean);

    const title = `Kanon - nå er du i gang!`;
    const subscriptionTypeText = isTrial
        ? (
            <>
                Du har startet din 30-dagers gratis prøveperiode av <strong>{planType}</strong> abonnement
            </>
        )
        : (
            <>
                Du har aktivert
                ditt <strong>{planType === 'fullpakke' ? 'Full Pakke' : 'Nettsideplan'}</strong> abonnement
            </>
        );

    return (
        <SuccessPageLayout
            title={title}
            subscriptionTypeText={subscriptionTypeText}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    flexWrap: 'no-wrap',
                    gap: {xs: '1em', sm: '3em', md: '7em', lg:'8em'},
                    marginTop: '2em',
                }}
            >
                {cards.map((card, index) => (
                    <ActionCard key={index} {...card} />
                ))}
            </Box>
        </SuccessPageLayout>
    );
};

export default SuccessPage;
