import Box from "@mui/system/Box";
import {CustomCard} from "../../customComponents/CustomCard";
import Typography from "@mui/material/Typography";
import React from "react";
import ReactPlayer from "react-player";
import '../../../assets/css/dashboard/BliKjent.css';

export const BliKjent = () => {
    return (
        <CustomCard id="bliKjentContainer" size="l">
            <Box>
                <Typography variant="h5" className="bliKjentTitle">Bli kjent med plattformen</Typography>
                <div className="video-container">
                    <ReactPlayer
                        url={"https://vimeo.com/1032792137/866c6d9738"}
                        controls
                        width='100%'
                        height='101%'
                        progressInterval={2000}
                        style={{
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            borderRadius: '8px',
                        }}
                        config={{
                            vimeo: {
                                playerOptions: {
                                    responsive: true,
                                    controls: true,
                                },
                            },
                        }}
                    />
                </div>
            </Box>
        </CustomCard>
    )

}