import React from 'react';
import { Box, Card, CardActions, CardContent, Typography, useMediaQuery } from '@mui/material';
import { CustomButton } from '../customComponents/CustomButton';
import { AnimationLinks } from '../../animations'

const MyWebsiteCards = ({ setActivePage, handleOpenAiBuilderModal, isCompact }) => {
    const isSmallScreen = useMediaQuery('(max-width:1440px)');
    const cardWidth = isCompact ? (isSmallScreen ? '450px' : '500px') : (isSmallScreen ? '550px' : '650px');
    const cardHeight = isSmallScreen ? '500px': '600px'; // Fixed height for all cards
    const videoAspectRatio = '4/4'; // Aspect ratio for consistent video sizing
    const templateSpanPadding = isSmallScreen ? '2em' : '0em';
    const isCompactJustifyContent = isCompact ? 'space-evenly' : 'flex-start';

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: isCompactJustifyContent,
                alignItems: 'flex-start',
                height: 'fit-content',
                maxWidth: 'none',
                mb: '3em',
                gap: '2em',
            }}
        >
            {/* First Card */}
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: cardWidth,
                    height: cardHeight,
                    padding: '2em',
                    transition: 'box-shadow 0.3s ease-in-out',
                    '&:hover': {
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
                    },
                }}
            >
                <CardContent>
                    <Typography variant="h3" component="div" sx={{ mb: 3, fontFamily: 'Fraunces' }}>
                        Start med en mal
                    </Typography>
                    <Typography
                        variant="body"
                        sx={{
                            textAlign: 'left',
                            display: 'flex',
                            justifyContent: 'center',
                            paddingLeft: templateSpanPadding,
                            fontWeight: '600',
                        }}
                    >
                        Velg mellom 200+ ferdige nettsideutkast
                    </Typography>
                </CardContent>
                <Box
                    sx={{
                        width: '100%',
                        aspectRatio: videoAspectRatio,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'hidden',
                        borderRadius: '5px',
                    }}
                >
                    <Box
                        component="video"
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                        src={AnimationLinks().malerAnimation}
                        preload="metadata"
                        autoPlay
                        loop
                        muted
                    />
                </Box>
                <CardActions sx={{ mt: 'auto', justifyContent: 'center' }}>
                    <CustomButton
                        colors="sixth"
                        variant="contained"
                        size="large"
                        sx={{ width: '90%' }}
                        onClick={() => setActivePage('Nettsidemaler')}
                    >
                        Velg et utkast
                    </CustomButton>
                </CardActions>
            </Card>

            {/* Second Card */}
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: cardWidth,
                    height: cardHeight,
                    padding: '2em',
                    transition: 'box-shadow 0.3s ease-in-out',
                    '&:hover': {
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
                    },
                }}
            >
                <CardContent>
                    <Typography variant="h3" component="div" sx={{ mb: 3, fontFamily: 'Fraunces' }}>
                        Bygg med AI
                    </Typography>
                    <Typography
                        variant="body"
                        sx={{
                            textAlign: 'left',
                            display: 'flex',
                            justifyContent: 'center',
                            paddingLeft: templateSpanPadding,
                            fontWeight: '600',
                        }}
                    >
                        La vår AI bygge et utkast til nettsiden din
                    </Typography>
                </CardContent>
                <Box
                    sx={{
                        width: '100%',
                        aspectRatio: videoAspectRatio,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'hidden',
                        borderRadius: '5px',
                    }}
                >
                    <Box
                        component="video"
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                        }}
                        src={AnimationLinks().aiByggerAnimation}
                        preload="metadata"
                        autoPlay
                        loop
                        muted
                    />
                </Box>
                <CardActions sx={{ mt: 'auto', justifyContent: 'center' }}>
                    <CustomButton
                        colors="quaternary"
                        variant="contained"
                        size="large"
                        sx={{ width: '90%' }}
                        onClick={handleOpenAiBuilderModal}
                    >
                        Start med AI
                    </CustomButton>
                </CardActions>
            </Card>
        </Box>
    );
};

export default MyWebsiteCards;
