import React, {useEffect} from 'react';
import {getSubscriptionStatus} from '../../helperFunctions/stripe/getSubscriptionStatus';
import SuccessPage from "../../components/paymentSuccess/SuccessPage";

const PaymentSuccessPage = () => {
    useEffect(() => {
        getSubscriptionStatus();
    }, []);

    return (
        <SuccessPage planType="fullpakke" isTrial={false} />
    );
};

export default PaymentSuccessPage;
